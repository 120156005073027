import React from "react"
import Layout from "../components/layout"

// Images
import payment from '../images/payment.png'

const Payment = props => {

    return ( 
        <Layout
            title = { 'Payment Method' }
        >
            <div className="site-About">
                <div 
                    style = {{
                        width: '100vw',
                        marginTop: '10vh',
                        minHeight : '90vh',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'center'
                    }}
                >
                    <h2>PAYMENT METHODS</h2>
                    <h3>We accept all major payment methods including Cash and Checks.</h3>
                    <img src = { payment } alt = {'Paymend methods'}/>
                </div>
            </div>
        </Layout>
    )
}
export default Payment
